<style lang="scss">
.branchenloesung-gesundheit {
  .head-row-text-normal {
    .text-normal {
      display: inline-block;
      text-transform: none !important;
    }
  }
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .custom-link-v2 {
    font-weight: 500;
    background: $blue-darker;
    color: #fff !important;
    padding: 10px 15px;
    display: inline-block;
    &:hover {
      opacity: 0.7;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .bild-box {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .text-center-small {
    @media (max-width: 992px) {
      text-align: center !important;
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-icon-margin {
      display: inline-block;
      margin: 15px !important;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="branchenloesung-gesundheit content container">
    <h1>Der Sonntag gehört der Gesundheit</h1>
    <p class="intro-txt">
      Platzieren Sie Ihre Werbebotschaft thematisch passend und zielgruppengenau in einem Umfeld, das zu den meist gelesenen Seiten der Kleinen Zeitung gehört. Wöchentlich werden Themen rund um gesundes und bewusstes Leben präsentiert.
    </p>

    <div class="margin-t-s"><h2>Ihre Werbelösungen für Gesundheit</h2></div>

    <TabGroup>
      <Tab tabid="gesundheit-tab1" :title="'Gesundheit am Sonntag'" active>
        <div class="row margin-tb-s">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h2>Gesundheit am Sonntag</h2>

            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss: </strong><br />
              Dienstag, 15.00 Uhr/Donnerstag, 15.00 Uhr
            </p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//branchenloesungen/gesundheit-hauptblatt.png" alt="Gesundheit am Sonntag" class="img-fluid" />
          </div>
        </div>

        <div class="text-center">
          <CollapsibleButton link="reise-raum-zeilenanzeigen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
        </div>
        <Collapsible link="reise-raum-zeilenanzeigen-tabellen">
          <h2>Raumanzeigen</h2>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Millimeterpreise </strong>
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">22,21</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">15,71</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">9,61</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>
          <!--<div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite</strong>
                  Fixformat: 47 x 45 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">779,81</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">551,66</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">337,55</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite</strong>
                  Fixformat: 98 x 45 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">1.559,61</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.103,31</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">675,09</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
            </div>
          </div>-->
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite </strong>
                  Format: 200 x 45 mm <small>(Reservierungspflichtig)</small>
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.197,14</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">3.676,14</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.248,74</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="200x45" class="img-fluid" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite Sonderformat Halbkugel </strong>
                  Format: Balken 200 x 30 mm; Kreis 65 mm Höhe, Radius 103 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.485,97</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">3.880,37</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.373,67</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x30-103x65.svg" alt="200x30-103x65" class="img-fluid" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/8 Seite</strong>
                  quer 98 x 65 mm / hoch 47 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.998,35</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">2.120,85</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.297,35</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x65.svg" alt="98x65" class="img-fluid tabelle-icon-margin" />
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid tabelle-icon-margin" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/4 Seite</strong>
                  quer 98 x 135 mm / hoch 47 x 260 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.996,70</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">4.241,70</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.594,70</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid tabelle-icon-margin" />
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47x260" class="img-fluid tabelle-icon-margin" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/2 Seite</strong>
                  quer 200 x 135 mm / hoch 98 x 260 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">11.334,60</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">8.127,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">4.973,40</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid tabelle-icon-margin" />
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid tabelle-icon-margin" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/1 Seite</strong>
                  Format: 200 x 275 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">24.431,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">17.281,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">10.571,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid" />
            </div>
          </div>

          <div class="info-txt-preis">Alle Preise in Euro exklusive Werbeabgabe und 20 % Umsatzsteuer.</div>
        </Collapsible>
      </Tab>

      <Tab tabid="gesundheit-tab2" :title="'Lebenslust'">
        <div class="row margin-tb-s">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h2>Lebenslust</h2>
            <p>
              Informationsplattform. Lust auf Lifestyle, Kulinarik, Gesundheit und mehr? <br />
              Mit unserem Themenschwerpunkt „Lebenslust“ bieten wir unseren Leserinnen und Lesern eine abwechslungsreiche Themenvielfalt und unseren Kunden ein ideales Werbeumfeld für die Präsentation ihres Unternehmens bzw. ihres Angebots.
            </p>
            <div class="row">
              <div class="col-lg-5">
                <strong>Erscheinungstage 2022</strong>
                <ul class="content-ul">
                  <li>12. Februar</li>
                  <li>06. März</li>
                  <li>10. April</li>
                  <li>08. Mai</li>
                  <li>12. Juni</li>
                  <li>11. September</li>
                  <li>09. Oktober</li>
                  <li>06. November</li>
                </ul>
              </div>
              <div class="col-lg-7">
                <strong>Anzeigenschluss / Druckunterlagenschluss</strong>
                <ul class="content-ul">
                  <li>02. Februar / 08. Februar</li>
                  <li>23. Februar / 01. März</li>
                  <li>30. März / 05. April</li>
                  <li>27. April / 03. Mai</li>
                  <li>01. Juni / 07. Juni</li>
                  <li>31. August / 06. September</li>
                  <li>28. September / 04. Oktober</li>
                  <li>25. Oktober / 31. Oktober</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center padding-tb-s">
            <img src="@/assets/img/img-content//branchenloesungen/titelseite-lebenslust.jpg" alt="Lebenslust" class="img-fluid shadow img-s-75 img-center" />
          </div>
        </div>

        <div class="text-center">
          <CollapsibleButton link="reisezeit-raum-zeilenanzeigen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
        </div>
        <Collapsible link="reisezeit-raum-zeilenanzeigen-tabellen">
          <h2>Klassische Inserate</h2>

          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite</strong>
                  Format: 47 x 45 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">1.270,35</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">897,75</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">549,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Aufschlagseite</strong>
                  Format: 98 x 45 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.540,70</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.795,50</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.098,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenseite 1/4 Seite*</strong>
                  Format: 47 x 260 mm (hoch), 98 x 135 mm (quer)
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.996,70</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">4.241,70</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.594,70</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47x260" class="img-fluid tabelle-icon-margin" />
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid tabelle-icon-margin" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenseite 1/2 Seite*</strong>
                  Format: 98 x 260 mm (hoch), 200 x 135 mm (quer)
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">11.993,40</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">8.483,40</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5.189,40</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid tabelle-icon-margin" />
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid tabelle-icon-margin" />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Innenteil 1/1 Seite</strong>
                  Format: 200 x 275 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">24.431,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">17.281,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">10.571,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>PR-Modul groß</strong>
                  Format: 98 x 130 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">3.754,40</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">2.654,60</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.625,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x130.svg" alt="98x130" class="img-fluid " />
            </div>
          </div>

          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>PR-Modul klein</strong>
                  Format: 47 x 130 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SO</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">1.877,20</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.327,30</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">812,50</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x130.svg" alt="47x130" class="img-fluid " />
            </div>
          </div>

          <div class="info-txt-preis text-center">* Tarif PR-Inserat zuzüglich PR-Produktionskostenbeitrag (1/4 Seite 65,00; 1/2 Seite 125,00; 1/1 Seite 225,00).</div>

          <div class="info-txt-preis">Alle Preise in Euro exklusive Werbeabgabe und 20 % Umsatzsteuer.</div>
        </Collapsible>
        <div class="download-pdf">
          <div class="line"><span>DOWNLOAD</span></div>
          <a href="../../pdf/branchenloesungen/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Download Lebenslust 2022
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>448 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </Tab>
      <Tab tabid="gesundheit-tab3" :title="'Digital'">
        <div class="row margin-t-s margin-b-m">
          <div class="col-lg-12 justify-content-center align-self-center text-center">
            <p>Ob klassisch mit <router-link :to="{ name: 'DisplayWerbung' }" class="content-link">Displaywerbung</router-link> oder native – Ihre Botschaften erreichen Ihre Kunden</p>
          </div>
        </div>
        <div class="row margin-b-m">
          <div class="col-lg-12">
            <h2>Gesundheits-Advertorial &amp; Native Ad</h2>
            <br />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Leistungen &amp; Tarif</h3>
            <ul class="content-ul">
              <li>Content-Produktion inkl. Bildersuche</li>
              <li>Korrektorat (2 Korrekturschleifen)</li>
              <li>Anlage des Artikels auf kleinezeitung.at/reise</li>
              <li>Native Ad (Mindestbuchungsvolumen: 500,00*)</li>
              <li>Vorlaufzeit 5 Werktage</li>
            </ul>
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Reise Advertorial &amp; Native Ad</div>
                <div class="col-lg-6 col-4 text-align-right">790,00</div>
              </div>
              <div class="info-txt-tabelle">*Der TKP ist abhängig vom Buchungsvolumen. Bei einem Buchungsvolumen von 500 Euro beträgt der TKP maximal 19,50 und reduziert sich bei höherem Kampagnenbudget entsprechend.</div>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <img src="@/assets/img/img-content//branchenloesungen/Devices-reise.png" alt="Devices Reise" class="img-fluid" />
          </div>
        </div>

        <div class="row margin-b-m">
          <div class="col-lg-12">
            <h2>Sponsored Story</h2>
            <br />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Leistungen &amp; Tarif</h3>
            <ul class="content-ul">
              <li>Ausführliches Briefing und Projektmanagement</li>
              <li>Content Produktion im redaktionellen Look &amp; Feel <small>(zwei Korrekturschleifen inklusive)</small></li>
              <li>Bewerbung auf kleinenzeitung.at, auf der Kleinen Zeitung-APP und in den sozialen Netzwerken</li>
              <li>Reporting der relevanten Kennzahlen</li>
              <li>Inkl. einmalige Integration im Kleine Zeitung-Mittags-Newsletter (für Stmk und Ktn)</li>
              <li>Vorlaufzeit: 5 Werktage</li>
            </ul>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">3.000 Leserinnen und Lesern</div>
                <div class="col-lg-6 col-4 text-align-right">4.985,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">1.500 Leserinnen und Lesern</div>
                <div class="col-lg-6 col-4 text-align-right">3.450,00</div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="row">
          <div class="col-lg-12">
            <div class="row justify-content-center align-self-center bg-blue-light highlight-box highlight-box-md  margin-b-m">
              <div class="col-md-6 d-flex align-self-center">
                <p>Sie wollen mehr über Native-Werbung wissen? </p>
              </div>
              <div class="col-md-6 d-flex align-self-center justify-content-end">
                <div class="btn-std btn-bg-blue-dark">
                  <router-link :to="{ name: 'Native' }">Hier erfahren Sie mehr!</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div class="row justify-content-center hinweis-box margin-t-s margin-b-m">
          <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
          <div class="col-lg-10 bg-right align-self-center">
            <p>Sie wollen mehr über Native-Werbung wissen? <router-link :to="{ name: 'Native' }" class="content-link">Hier erfahren Sie mehr!</router-link></p>
          </div>
        </div>
        <div class="row margin-b-m">
          <div class="col-lg-12">
            <h2>Upgrade: Sponsored Post</h2>
            <br />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Leistungen &amp; Tarif</h3>
            <ul class="content-ul">
              <li>Insgesamt 400 garantierte Klicks</li>
            </ul>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Upgrade: Sponsored Post</div>
                <div class="col-lg-6 col-4 text-align-right">610,00</div>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
        <div class="row margin-b-m">
          <div class="col-lg-12">
            <h2>Livestream</h2>
            <br />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Leistungen &amp; Tarif</h3>
            <p>Nutzen Sie das innovative Format, um als Expertin oder Experte gesundheitsrelevante Fragen unserer Userinnen und User zu beantworten – moderiert von der Kleinen Zeitung.</p>
            <p>Die crossmediale Bewerbung – im Gesundheitsteil der Kleinen Zeitung, als Sponsored Story auf den Sozialen Kanälen und als Display-Werbung – sorgt für hohe Reichweiten in Ihrer Kernzielgruppe. Beispiele zum Nachschauen: <a href="https://www.kleinezeitung.at/gesundheitstalk" target="_blank" class="content-link">www.kleinezeitung.at/gesundheitstalk</a></p>
          </div>
          <div class="col-lg-6 align-self-center">
            <img src="@/assets/img/img-content//branchenloesungen/gesundheit-desktop.png" alt="Desktop Gesundheit" class="img-fluid" />
          </div>
        </div>
        <!-- -->
        <div class="row margin-b-m">
          <div class="col-lg-12">
            <h2>Kleine-Zeitung-APP und Kleine-Zeitung-Ärzte-APP</h2>
            <br />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h3>Leistungen &amp; Tarif</h3>
            <p>
              Der Trend ist klar: Immer mehr Kleine-Zeitung-User greifen sehr gerne zu mobilen Endgeräten, um sich schnell die neuesten Informationen zu holen. Verstärken Sie Ihr Print-Inserat mit einem digitalen Werbemittel und steigern so die Bekanntheit Ihrer Marke durch hohe Reichweite. Erreichen Sie Ihre Zielgruppe auch unterwegs und setzen Sie zur Verstärkung Ihrer klassischen Print-Werbung
              auf mobile Werbemittel wie Push-Nachrichten auf unserer Kleine-Zeitung-Ärzte-App.
            </p>
          </div>
          <div class="col-lg-6 align-self-center">
            <img src="@/assets/img/img-content//branchenloesungen/gesundheit-smartphone.png" alt="Devices Gesundheit" class="img-fluid" />
          </div>
        </div>
      </Tab>
      <Tab tabid="gesundheit-tab4" :title="'Themenmagazin Gesundheit'">
        <div class="row margin-tb-s">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h2>Themenmagazin Gesundheit</h2>
            <p>
              Für unsere gesundheitsbewussten Leser mit nationaler Zusatzverteilung an Apotheker und Ärzte!
            </p>
            <div class="row">
              <div class="col-lg-12">
                <strong>Erscheinungstermin</strong>
                <ul class="content-ul">
                  <li>19. November 2022</li>
                </ul>
              </div>
              <div class="col-lg-12">
                <strong>Anzeigenschluss </strong>
                <ul class="content-ul">
                  <li>28. Oktober 2022 (12 Uhr)</li>
                </ul>
              </div>
              <div class="col-lg-12">
                <strong>Druckunterlagenschluss</strong>
                <ul class="content-ul">
                  <li>04. November 2022 (12 Uhr)</li>
                </ul>
              </div>
              <div class="col-lg-12">
                <br />
                <p><strong>Die Tarife entnehmen Sie dem untenstehenden Link.</strong></p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center padding-tb-s">
            <img src="@/assets/img/img-content//branchenloesungen/themenmagazin-gesundheit-titelseite.jpg" alt="Themenmagazin Gesundheit" class="img-fluid shadow img-s-75 img-center" />
          </div>
        </div>

        <div class="download-pdf">
          <div class="line"><span>DOWNLOAD</span></div>
          <a href="../../pdf/branchenloesungen/Kleine-Zeitung-Themenmagazin-Gesundheit-2022.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Themenmagazin Gesundheit
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>665 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </Tab>
    </TabGroup>
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/branchenloesungen/Kleine-Zeitung-Gesundheit-Branchenfolder-2022.pdf?=03062022" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Download Gesundheit Branchenfolder
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>1.648 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  components: {
    //Modal,
    TabGroup,
    Tab,
    CollapsibleButton,
    Collapsible,
  },
});
</script>
